<template>
  <el-card id="promotion">
    <header>
      
      <div class="search">
        <div class="left"><h2>推广</h2></div>
        <div class="right">
          <el-button type="primary" @click="onTktz"
            >探客分享<i class="el-icon-share"></i
          ></el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>
    <main>
      <div class="top-box">
        <el-table
          :data="sumData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
        >
          <el-table-column
            prop="sumCount"
            label="推广数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sumMoney"
            label="佣金"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="successCount"
            label="成交数"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <template>
              <el-button type="primary" @click="payBtn" size="mini"
                >绑定支付宝
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        style="margin-top: 20px"
      >
        <el-table-column
          prop="product"
          label="产品"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="公司名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="peopleCount"
          label="公司规模"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="是否提交" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 0">未成交</span>
            <span v-if="scope.row.status == 1">已成交</span>
            <span v-if="scope.row.status == 2">驳回</span>
            <span v-if="scope.row.status == 3">成交未达标</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="money"
          label="成交金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amoney"
          label="推荐者获取金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bmoney"
          label="被推荐者获取金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rate"
          label="费率"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="seatRate"
          label="月租"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="successTime"
          label="成交时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="aphone"
          label="推荐者号码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bphone"
          label="被推荐者号码"
          align="center"
        ></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 绑定支付信息弹出框 -->
    <el-dialog title="绑定支付信息" :visible.sync="payVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="绑定账户">
          <el-input v-model="bankCard" placeholder="绑定账户"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="paySubmit()">确 定</el-button>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import axios from "axios";
export default {
  data() {
    return {
      payVisible: false,
      bankCard: null,
      tktz: "http://tktz.cddmt.cn/#/recommendation?phone=",
      sumData: [],
      aphone: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.aphone = localStorage.getItem("ms_username");
    this.realname = localStorage.getItem("realname");
    this.getList();
    this.getPay();
  },
  methods: {
    // 探客分享
    onTktz() {
      window.open(this.tktz + this.aphone, "_blank");
    },
    // 绑定支付宝
    payBtn() {
      this.payVisible = true;
    },
    // 探客查询绑定支付信息
    getPay() {
      let params = {
        username: this.aphone,
      };
      axios
        .post("https://tktz2.cddmt.cn/tk/user-bankCard/findOne", params)
        .then((res) => {
          this.bankCard = res.data.data.bankCard;
        });
    },
    // 探客添加与修改绑定支付信息
    paySubmit() {
      let params = {
        username: this.aphone,
        bankCard: this.bankCard,
      };
      axios
        .post("https://tktz2.cddmt.cn/tk/user-bankCard/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
          this.payVisible = false;
        });
    },
    // 探客创建用户
    addUser() {
      let password = this.aphone.slice(0, 6);
      let params = {
        username: this.aphone,
        realname: this.realname,
        password,
      };
      axios.post("https://tktz2.cddmt.cn/tk/user/add", params);
    },
    // 探客获取当前人推广次数
    getListSum() {
      let params = {
        aphone: this.aphone,
      };
      axios
        .post("https://tktz2.cddmt.cn/tk/recommend/dayAndmoney", params)
        .then((res) => {
          let data = [];
          data.push(res.data.data);
          this.sumData = data;
        });
    },
    // 探客查询当前人推广信息
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        aphone: this.aphone,
      };
      axios
        .post("https://tktz2.cddmt.cn/tk/recommend/list-nologin", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
          if (res.data.objData.noUser == true) {
            this.getListSum();
          } else {
            this.addUser();
          }
        });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;
  &:hover {
    opacity: 0.7;
  }
}
#promotion {
  height: 100%;
  overflow-y: scroll;
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 10px;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
          // margin-top: 20px;
          text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
    /deep/.top-box {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
     
      .hover-row {
        color: #000 !important;
      }
      .el-table__row{
        color: #000;
        background-color: #fff !important;
      }
      tr,
      .table-header {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        background-color: #3278FE !important;
      }
    }
  }
}
</style>